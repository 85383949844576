@tailwind base;
@tailwind components;
@tailwind utilities;



.App {
    background: linear-gradient(-24deg, #eef2f3, #dadee2) !important;

}

.App.dark-mode {
    background: #080C08 !important;

}


.top-navbar {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999;
    top: 20px;
    background: transparent;
    transition: transform 0.3s ease-in-out !important;

}

.top-navbar .navbar-linear-bg {
    width: 65%;
    border-radius: 80px;
    background: linear-gradient(138.58deg, rgba(180, 198, 202, 0.25) 0%, rgba(180, 198, 202, 0.05) 100%) !important;
    padding: 2px;
}


.top-navbar .navbar {
    width: 100%;
    height: 56px;
    border-radius: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
    padding-left: 4px;
    transition: top 0.3s ease;
    margin: 0 auto;
}


.top-navbar .nav-start {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    padding-left: 15px;
}

.top-navbar .logo {
    width: 100%;
    height: auto;
}

.top-navbar .navbar-light {
    background: rgba(212, 212, 212, 0.25);
    backdrop-filter: blur(85px);
    -webkit-backdrop-filter: blur(85px);
}

.top-navbar .navbar-dark {
    background: #080C0873;
    backdrop-filter: blur(85px);
    -webkit-backdrop-filter: blur(85px);
}


.top-navbar .nav-center {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.top-navbar .h-text-1 {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 700;
}

.top-navbar .h-text-1-light {
    color: #080C08
}

.top-navbar .h-text-1-dark {
    color: #FFFFFF;
}

.top-navbar .h-text-2 {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}

.top-navbar .h-text-2-light {
    color: #080C08;
}

.top-navbar .h-text-2-dark {
    color: #FFFFFF;
}

.top-navbar .nav-link {
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 20px;
}

.top-navbar .nav-link.active {
    background: #8886861a;
}


.top-navbar .nav-end {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 5px;
}

.top-navbar .btn-join {
    height: 40px;
    padding: 5px;
    padding-left: 16px;
    padding-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 40px;
    font-size: 14px;
    font-weight: 600;
}


.top-navbar .btn-join-light {
    color: #EEF2F3;
    background: #1A2B1A;
    border: 2px solid #1A2B1A;
}

.top-navbar .btn-join-dark {
    color: #1A2B1A;
    background: #ADF075;
    border: 2px solid #1A2B1A;
}


.top-navbar .btn-toggle-mobile-dark {

    border: none;
    background: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-navbar .btn-toggle-mobile-light {

    /* background: #1A2B1A;
    border: 2px solid #1A2B1A; */
    border: none;
    background: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

}


.top-navbar .menu-icon-dark {
    width: 24px;
    height: 24px;
    filter: brightness(0) saturate(100%) invert(95%) sepia(0%) saturate(7465%) hue-rotate(252deg) brightness(116%) contrast(100%);
}

.top-navbar .menu-icon-light {
    width: 24px;
    height: 24px;
    filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7500%) hue-rotate(8deg) brightness(99%) contrast(105%);

}


.min-vh100 {
    min-height: 100vh;
}


.nav-des {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.mobile-menu-bg {
    background: linear-gradient(138.58deg, rgba(180, 198, 202, 0.25) 0%, rgba(180, 198, 202, 0.05) 100%) !important;
    padding: 2px !important;
}


.mobile-menu {
    position: fixed;
    top: 50px;
    left: 50%;
    width: 90%;
    height: 90%;
    z-index: -1;
    padding: 10px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: top 0.3s ease-in-out;
}

.top-navbar .mobile-nav-start {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
}

.top-navbar .mobile-p {

    font-size: 14px;
    font-weight: 500;
}

.top-navbar .mobile-p-dark {
    color: white;
}

.top-navbar .mobile-p-lite {
    color: #454A45;
}


.top-navbar .mobile-menu-dark {
    background: #080C0873;
    backdrop-filter: blur(85px);
    -webkit-backdrop-filter: blur(85px);

}

.top-navbar .mobile-menu-lite {
    background: rgba(212, 212, 212, 0.25);
    backdrop-filter: blur(85px);
    -webkit-backdrop-filter: blur(85px);
}

.mobile-menu .nav-link-mobile {
    text-decoration: none;
}

.mobile-menu .nav-link-mobile .h-text-2 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 700;
}

.mobile-menu .nav-link-mobile .h-text-2-dark {
    color: #FFFFFF;
}

.mobile-menu .nav-link-mobile .h-text-2-lite {
    color: #080C08
}

.slide-in {
    transform: translateX(-50%) translateX(0);
    transition: all 0.5s ease;
    /* Fully visible */
}

.slide-out {
    transform: translateX(-50%) translateX(-100%);
    transition: all 0.5s ease;

    /* Hidden */
}

/* -------------- */

.h-section-3 .card {
    background: none;
    border-radius: 0px;
    border: 1px solid #ADF0752E;
}

.h-section-3 .no-border-top .card {
    border-top: none !important;
}

.h-section-3 .no-border-bottom .card {
    border-bottom: none !important;
}

.h-section-3 .no-border-top .card {
    border-top: none;
}

.h-section-3 .no-border-bottom .card {
    border-bottom: none;
}

.h-section-4 .b-right-4 {
    border-right: 1px solid #1A2B1A;
}
.h-section-5 .btn-banner-join{
background:#1A2B1A;
color:#EEF2F3;
}

.h-section-6 .mobile-row img {
    margin: 0 auto;

}

.h-section-7 .btn-start {
    display: none;
    cursor: pointer;
    z-index: 10;
    position: absolute;
    left: 15px;
}


.h-section-7 .marquee-item:hover .btn-start {
    display: block;
}

.h-banner {}

.h-banner .span-element {
    display: inline-block;
    opacity: 0;
    filter: blur(10px);
    transform: translateY(20%);
    animation: reveal 1s ease forwards;
    margin: 0px 2px;

}

.h-banner .span-element:nth-child(1),
.h-banner .span-element:nth-child(2),
.h-banner .span-element:nth-child(3) {
    animation-delay: 0s;
}

.h-banner .span-element:nth-child(4),
.h-banner .span-element:nth-child(5),
.h-banner .span-element:nth-child(6) {
    animation-delay: 0.2s;
}

.h-banner .span-element:nth-child(7),
.h-banner .span-element:nth-child(8),
.h-banner .span-element:nth-child(9) {
    animation-delay: 0.4s;
}

.h-banner .span-element:nth-child(10),
.h-banner .span-element:nth-child(11),
.h-banner .span-element:nth-child(12) {
    animation-delay: 0.6s;
}

.h-banner .span-element:nth-child(13),
.h-banner .span-element:nth-child(14),
.h-banner .span-element:nth-child(15) {
    animation-delay: 0.6s;
}

.h-banner .span-element-1 {
    display: inline-block;
    opacity: 0;
    filter: blur(10px);
    transform: translateY(20%);
    animation: reveal 2s ease forwards;
    margin: 0px 2px;
}

.h-banner .span-element-1:nth-child(1),
.h-banner .span-element-1:nth-child(2),
.h-banner .span-element-1:nth-child(3) {
    animation-delay: 0s;
}

.h-banner .span-element-1:nth-child(4),
.h-banner .span-element-1:nth-child(5),
.h-banner .span-element-1:nth-child(6) {
    animation-delay: 0.2s;
}

.h-banner .span-element-1:nth-child(7),
.h-banner .span-element-1:nth-child(8),
.h-banner .span-element-1:nth-child(9) {
    animation-delay: 0.4s;
}

.h-banner .span-element-1:nth-child(10),
.h-banner .span-element-1:nth-child(11),
.h-banner .span-element-1:nth-child(12) {
    animation-delay: 0.6s;
}

.h-banner .span-element-1:nth-child(13),
.h-banner .span-element-1:nth-child(14),
.h-banner .span-element-1:nth-child(15) {
    animation-delay: 0.6s;
}

.h-banner .btn-banner-join {
    opacity: 0;
    filter: blur(10px);
    transform: translateY(20%);
    animation: reveal 3s ease forwards;
}

.h-banner .banner-img {
    mix-blend-mode: darken !important;
}




@keyframes reveal {
    0% {
        opacity: 0;
        filter: blur(10px);
        transform: translateY(20%);
        will-change: opacity, filter, transform;
    }

    100% {
        opacity: 1;
        filter: blur(0px);
        transform: translateY(0%);
        will-change: auto;
    }
}




/* --------------- */

.join-modal .modal {
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(0);
    opacity: 1;
}

.join-modal .modal.fade {
    opacity: 0;
    transform: translateY(100%);
}

.join-modal .modal.fade.show {
    opacity: 1;
    transform: translateY(0);
}

.join-modal .modal-content {
    background-color: #eef2f3;
}

.join-modal .modal-header {
    border: none;
}


.join-modal .modal-navbar {  
    background: rgba(212, 212, 212, 0.25);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.join-modal .modal-fullscreen .modal-body {
    overflow-y: visible;
}

.join-modal .form-label {
    margin-bottom: 5px;
}

.join-modal .form-control {
    border: 1px solid #1A2B1A33;
    background: #D4D4D426;
    border-radius: 14px;
    color: #080C08;
}

.join-modal .form-select {
    height: 45px;
    border: 1px solid #1A2B1A33;
    background: #D4D4D426;
    border-radius: 14px;
    color: #080C08;
    font-size: 14px;
    font-weight: 500;
    color: #8D908F;
}

.join-modal .form-select:focus {
    box-shadow: none;
    background: #D4D4D426;
    border: 1.5px solid #1A2B1A;
}

.join-modal .form-select option {
    height: 100px !important;
    overflow-y: auto;
    font-size: 12px;
    font-weight: 500;
    color: #8D908F;
    padding: 10px;
    cursor: pointer;
    padding: 10px;
}

.join-modal .form-control::placeholder {
    font-weight: 500;
    color: #8D908F;
}

.join-modal .form-control:focus {
    box-shadow: none;
    background: #D4D4D426;
    border: 1.5px solid #1A2B1A;
}

.join-modal .modal-col-2 {
    border-left: 1px solid #878A8A;
    padding: 0px 25px;
}

.join-modal .dropdown-header {
    height: 45px;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #1A2B1A33;
    background: #D4D4D426;
    cursor: pointer;
    position: relative;
    border-radius: 14px;
}

.join-modal .dropdown-body {
    margin-top: 5px;
    border-radius: 10px;
    border: 1px solid #1A2B1A33;
    transition: all 0.5s ease;
    padding-bottom: 10px;
}

.join-modal .search-input::placeholder {
    font-weight: 500;
    color: #8D908F;
}



.join-modal .search-input {
    font-size: 14px;
    color: #080C08;
    width: 100%;
    padding: 10px;
    border-radius: 14px;
    border: none;
    background: none;

}

.join-modal .search-input :focus-within {
    box-shadow: none;
    background: #D4D4D426;
    border: 1.5px solid red !important;
    outline: none;
}

.join-modal .dropdown-options {
    max-height: 150px !important;
    overflow-y: auto;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    background-color: transparent;
}


/* ------------- */

.about .card-1 {
    border-right: 1px solid #ADF0752E;
    border-radius: 0;
}

/* ---------------- */

.contact .card {
    border-radius: 25px;
    border: 3px solid linear-gradient(138.58deg, rgba(180, 198, 202, 0.25) 0%, rgba(180, 198, 202, 0.05) 100%);
    background: #D4D4D41A;
}

/* ------- animation */
.App {
    /* background-color: red; */
    transition: all background-color 0.8s ease, border-color 0.8s ease;
}

.App.black {
    background: transparent;
    background-color: #080C08;
    transition: background-color 0.8s ease;
}

.bg-black {
    background-color: #000;
}

.text-white {
    color: #fff;
    transition: background-color 0.5s ease;
}

.text-black {
    color: #000;
}

.bg-white {
    background-color: #fff;
    transition: background-color 0.5s ease;
}



/* -----animation */
.custom-cursor {
    position: fixed;
    z-index: 999;
    height: 40px;
    width: 200px;
    background-color: #adf075;
    border: 2px solid #1A2B1A;
    border-radius: 40px;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.custom-cursor button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
}


.p-text-2 {
    cursor: pointer;
}





/* ---privacypolicy----- */

.privacy-policy {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.privacy-policy .privacy-body {
    width: 70%;
    padding-top: 120px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.privacy-policy .p-head {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 15px;
}

.privacy-policy .h-text-1 {
    font-size: 48px;
    font-weight: 700;
    color: #1A2B1A;
    margin-bottom: 0;
}

.privacy-policy .p-text-1 {
    font-size: 16px;
    font-weight: 500;
    color: #454A45;
    margin-bottom: 0;
}

.privacy-policy .p-body {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 20px;
}

.privacy-policy .p-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 6px;
}

.privacy-policy .h-text-2 {
    font-size: 16px;
    font-weight: 700;
    color: #1A2B1A;
    margin-bottom: 0;
}

.privacy-policy .p-text-2 {
    font-size: 16px;
    font-weight: 500;
    color: #454A45;
    margin-bottom: 0;
    line-height: 23px;
    cursor: inherit;
}

.privacy-policy a {
    text-decoration: none;
    color: #537E30;
}

.data-usage ul {
    list-style-type: disc
}


@media (min-width: 2561px) and (max-width: 3840px) {

    .h-banner .h-text-1 {
        font-size: 9rem;
    }

    .h-banner .p-text-1 {
        font-size: 2.388rem;
    }

    .h-banner .btn-banner-join {
        font-size: 2rem;
    }

    .h-section-3 .div-revolutionary p {
        font-size: 0.75rem;
    }

    .h-section-3 .h-text-1 {
        font-size: 6rem;
    }

    .h-section-3 .p-text-1 {
        font-size: 2.388rem;
    }

    .h-section-3 .h-text-2 {
        font-size: 1.25rem;
    }

    .h-section-3 .p-text-2 {
        font-size: 1.25rem;
    }

    .h-section-4 .h-text-1 {
        font-size: 2.625rem;
    }

    .h-section-4 .p-text-1 {
        font-size: 1.375rem;
    }

    .h-section-4 .h-text-2 {
        font-size: 3rem;
    }

    .h-section-4 .p-text-2 {
        font-size: 1.375rem;
    }

    .h-section-5 .job-card-div p {
        font-size: 1.6rem;
    }
/* 
    .h-section-5 .card-hsec-5-m {
        margin-top: 64px;
    } */

    .h-section-5 .h-text-1 {
        font-size: 3.5rem;
    }

    .h-section-5 .p-text-1 {
        font-size: 1.375rem;
    }

    .h-section-5 .h-text-2 {
        font-size: 1.375rem;
    }

    .h-section-5 .btn-banner-join {
        font-size: 2rem;
    }

    .h-section-6 .div-management p {
        font-size: 0.75rem;
    }

    .h-section-6 .h-text-1 {
        font-size: 4rem;
    }

    .h-section-6 .p-text-1 {
        font-size: 1.625rem;
    }

    .h-section-6 .h-text-2 {
        font-size: 2.5rem;
    }

    .h-section-6 .p-text-3 {
        font-size: 1.25rem;
    }

    .h-section-6 .p-text-4 {
        font-size: 1.625rem;
    }

    .h-section-6 .h-text-3 {
        font-size: 2.5rem;
    }

    .h-section-6 .p-text-2 {
        font-size: 1.625rem;
    }

    .h-section-7 .access-div p {
        font-size: 1.6rem;
    }

    .h-section-7 .h-text-1 {
        font-size: 4rem;
    }

    .h-section-7 .p-text-1 {
        font-size: 1.625rem;
    }

    .h-section-7 .p-text-2 {
        font-size: 9.75rem;
    }

    .footer .p-text-1 {
        font-size: 1.5rem;
    }

    .footer .foot-link {
        font-size: 1.5rem;
    }

    .footer .h-text-1 {
        font-size: 1.75rem;
    }

    .footer .h-text-2 {
        font-size: 1.625rem;
    }

    .footer .btn-join-foot {
        font-size: 1.25rem;
    }

    .about .h-text-1 {
        font-size: 9rem;
    }

    .about .p-text-1 {
        font-size: 2.388rem;
    }

    .about .h-text-2 {
        font-size: 6rem;
    }

    .about .p-text-2 {
        font-size: 2.388rem;
    }

    .about .story-div p {
        font-size: 0.75rem;
    }

    .contact .h-text-1 {
        font-size: 9rem;
    }

    .contact .p-text-1 {
        font-size: 2.388rem;
    }

    .contact .h-text-2 {
        font-size: 2.125rem;
    }

    .contact .p-text-2 {
        font-size: 1.5rem;
    }

    .contact .div-contact p {
        font-size: 0.75rem;
    }

    .join-modal .r-h-text-1 {
        font-size: 4.5rem;
    }

    .join-modal .r-p-text-1 {
        font-size: 1.75rem;
    }

    .join-modal .r-p-text-2 {
        font-size: 1.625rem;
    }

    .join-modal .r-p-text-3 {
        font-size: 1.625rem;
    }

    .join-modal .h-text-1 {
        font-size: 8rem;
    }

    .join-modal .p-text-1 {
        font-size: 3rem;
    }

    .join-modal .p-text-2 {
        font-size: 1.375rem;
    }

    .join-modal .form-control {
        height: 100px;
        margin-bottom: 10px;
        font-size: 1.75rem
    }

    .join-modal .form-select {
        height: 100px;
        margin-bottom: 10px;
        font-size: 1.75rem
    }

    .join-modal .dropdown-header {
        height: 100px;
        margin-bottom: 10px;
        font-size: 1.75rem
    }

    .join-modal .form-control::placeholder {
        font-size: 1.75rem
    }

    .join-modal .form-label {
        font-size: 1.75rem
    }

    .join-modal .modal-join-btn {
        height: 100px;
        font-size: 2rem;
        border-radius: 80px;
    }

    .join-modal .p-text-3 {
        font-size: 1.375rem;
    }

    .join-modal .modal-col-2 {
        padding-left: 80px;
    }
}


@media (min-width: 1921px) and (max-width: 2560px) {
    .h-banner .h-text-1 {
        font-size: 7rem;
    }

    .h-banner .p-text-1 {
        font-size: 2rem;
    }

    .h-banner .btn-banner-join {
        font-size: 1.8rem;
    }

    .h-section-3 .div-revolutionary p {
        font-size: 0.875rem;

    }

    .h-section-3 .h-text-1 {
        font-size: 5rem;
    }

    .h-section-3 .p-text-1 {
        font-size: 2rem;
    }

    .h-section-3 .h-text-2 {
        font-size: 1.25rem;
    }

    .h-section-3 .p-text-2 {
        font-size: 1.25rem;
    }

    .h-section-4 .h-text-1 {
        font-size: 2.375rem;
    }

    .h-section-4 .p-text-1 {
        font-size: 1.25rem;
    }

    .h-section-4 .h-text-2 {
        font-size: 3rem;
    }

    .h-section-4 .p-text-2 {
        font-size: 1.375rem;
    }

    .h-section-5 .job-card-div p {
        font-size: 1.4rem;
    }

    .h-section-5 .h-text-1 {
        font-size: 2.5rem;
    }

    /* .h-section-5 .card-hsec-5-m {
        margin-top: 48px;
    } */

    .h-section-5 .p-text-1 {
        font-size: 1.25rem;
    }

    .h-section-5 .h-text-2 {
        font-size: 1.25rem;
    }

    .h-section-5 .btn-banner-join {
        font-size: 1rem;
    }

    .h-section-6 .div-management p {
        font-size: 0.75rem;

    }

    .h-section-6 .h-text-1 {
        font-size: 3.625rem;
    }

    .h-section-6 .p-text-1 {
        font-size: 1.5rem;
    }

    .h-section-6 .h-text-2 {
        font-size: 2rem;
    }

    .h-section-6 .p-text-3 {
        font-size: 1.125rem;
    }

    .h-section-6 .p-text-4 {
        font-size: 1.5rem;
    }

    .h-section-6 .h-text-3 {
        font-size: 2.5rem;
    }

    .h-section-6 .p-text-2 {
        font-size: 1.5rem;
    }

    .h-section-7 .access-div p {
        font-size: 1.4rem;
    }

    .h-section-7 .h-text-1 {
        font-size: 3.625rem;
    }

    .h-section-7 .p-text-1 {
        font-size: 1.5rem;
    }

    .h-section-7 .p-text-2 {
        font-size: 8.75rem;
    }

    .footer .p-text-1 {
        font-size: 1.375rem;
    }

    .footer .foot-link {
        font-size: 1.375rem;
    }

    .footer .h-text-1 {
        font-size: 1.625rem;
    }

    .footer .h-text-2 {
        font-size: 1.5rem;
    }

    .footer .btn-join-foot {
        font-size: 1.125rem;
    }

    .about .h-text-1 {
        font-size: 7rem;
    }

    .about .p-text-1 {
        font-size: 2rem;
    }

    .about .h-text-2 {
        font-size: 5rem;
    }

    .about .p-text-2 {
        font-size: 2rem;
    }

    .about .h-text-3 {
        font-size: 2.25rem;
    }

    .about .p-text-3 {
        font-size: 2rem;
    }


    .about .story-div p {
        font-size: 0.75rem;
    }

    .contact .h-text-1 {
        font-size: 7rem;
    }

    .contact .p-text-1 {
        font-size: 2rem;
    }

    .contact .h-text-2 {
        font-size: 1.875rem;
    }

    .contact .p-text-2 {
        font-size: 1.625rem;
    }

    .contact .div-contact p {
        font-size: 0.75rem;
    }

    .join-modal .r-h-text-1 {
        font-size: 4.125rem;
    }

    .join-modal .r-p-text-1 {
        font-size: 1.625rem;
    }

    .join-modal .r-p-text-2 {
        font-size: 1.5rem;
    }

    .join-modal .r-p-text-3 {
        font-size: 1.5rem;

    }



    .join-modal .h-text-1 {
        font-size: 6.5rem;
    }

    .join-modal .p-text-1 {
        font-size: 2.5rem;
    }

    .join-modal .p-text-2 {
        font-size: 1.375rem;
    }

    .join-modal .form-control {
        height: 80px;
        margin-bottom: 10px;
        font-size: 1.75rem
    }

    .join-modal .form-select {
        height: 80px;
        margin-bottom: 10px;
        font-size: 1.75rem
    }

    .join-modal .dropdown-header {
        height: 80px;
        margin-bottom: 10px;
        font-size: 1.75rem
    }

    .join-modal .form-control::placeholder {
        font-size: 1.75rem
    }

    .join-modal .form-label {
        font-size: 1.75rem
    }

    .join-modal .modal-join-btn {
        height: 80px;
        font-size: 2rem;
        border-radius: 80px;
    }

    .join-modal .p-text-3 {
        font-size: 1.375rem;
    }

    .join-modal .modal-col-2 {
        padding-left: 80px;
    }

}

@media (min-width: 1441px) and (max-width: 1920px) {
    .h-banner .h-text-1 {
        font-size: 4.5rem;
    }

    .h-banner .p-text-1 {
        font-size: 1.75rem;
    }

    .h-banner .btn-banner-join {
        font-size: 1rem;
    }

    .h-section-3 .div-revolutionary p {
        font-size: 0.75rem;

    }

    .h-section-3 .h-text-1 {
        font-size: 3rem;
    }

    .h-section-3 .p-text-1 {
        font-size: 1.125rem
    }


    .h-section-3 .h-text-2 {
        font-size: 1.125rem
    }

    .h-section-3 .p-text-2 {
        font-size: 1.125rem
    }

    .h-section-4 .h-text-1 {
        font-size: 2rem;
    }

    .h-section-4 .p-text-1 {
        font-size: 1rem;
    }

    .h-section-4 .h-text-2 {
        font-size: 2.875rem;
    }

    .h-section-4 .p-text-2 {
        font-size: 1.25rem;
    }

    .h-section-5 .job-card-div p {
        font-size: 0.875rem;
    }

    .h-section-5 .card-hsec-5-m {
        margin-top: 32px;
    }

    .h-section-5 .h-text-1 {
        font-size: 2.375rem;
    }

    .h-section-5 .p-text-1 {
        font-size: 1.125rem;
    }

    .h-section-5 .h-text-2 {
        font-size: 1.125rem;
    }

    .h-section-5 .btn-banner-join {
        font-size: 1rem;
    }

    .h-section-6 .div-management p {
        font-size: 0.75rem;

    }

    .h-section-6 .h-text-1 {
        font-size: 3.375rem;
    }

    .h-section-6 .p-text-1 {
        font-size: 1.5rem;
    }

    .h-section-6 .h-text-2 {
        font-size: 1.875rem;
    }

    .h-section-6 .p-text-3 {
        font-size: 1.125rem;
    }

    .h-section-6 .p-text-4 {
        font-size: 1.375rem;
    }

    .h-section-6 .h-text-3 {
        font-size: 2.25rem;
    }

    .h-section-6 .p-text-2 {
        font-size: 1.375rem;
    }

    .h-section-7 .access-div p {
        font-size: 1.4rem;
    }

    .h-section-7 .h-text-1 {
        font-size: 3.375rem;
    }

    .h-section-7 .p-text-1 {
        font-size: 1.5rem;
    }

    .h-section-7 .p-text-2 {
        font-size: 7.875rem;
    }

    .footer .p-text-1 {
        font-size: 1.25rem;
    }

    .footer .foot-link {
        font-size: 1.25rem;
    }

    .footer .h-text-1 {
        font-size: 1.5rem;
    }

    .footer .h-text-2 {
        font-size: 1.375rem;
    }

    .footer .btn-join-foot {
        font-size: 1.125rem;
    }

    .about .h-text-1 {
        font-size: 5.5rem;
    }

    .about .p-text-1 {
        font-size: 1.75rem;
    }

    .about .h-text-2 {
        font-size: 4rem;
    }

    .about .p-text-2 {
        font-size: 1.75rem;
    }

    .about .h-text-3 {
        font-size: 2.125rem;
    }

    .about .p-text-3 {
        font-size: 1.75rem;
    }

    .about .story-div p {
        font-size: 0.75rem;
    }

    .contact .h-text-1 {
        font-size: 5.5rem;
    }

    .contact .p-text-1 {
        font-size: 1.75rem;
    }

    .contact .h-text-2 {
        font-size: 1.75rem;
    }

    .contact .p-text-2 {
        font-size: 1.625rem;
    }

    .contact .div-contact p {
        font-size: 0.75rem;
    }

    .join-modal .r-h-text-1 {
        font-size: 3.875rem;
    }

    .join-modal .r-p-text-1 {
        font-size: 1.5rem;
    }

    .join-modal .r-p-text-2 {
        font-size: 1.375rem;
    }

    .join-modal .r-p-text-3 {
        font-size: 1.375rem;
    }



    .join-modal .h-text-1 {
        font-size: 5.5rem;
    }

    .join-modal .p-text-1 {
        font-size: 1.875rem;
    }

    .join-modal .p-text-2 {
        font-size: 1.25rem;
    }

    .join-modal .form-control {
        height: 80px;
        margin-bottom: 10px;
        font-size: 1.5rem
    }

    .join-modal .form-select {
        height: 80px;
        margin-bottom: 10px;
        font-size: 1.5rem
    }

    .join-modal .dropdown-header {
        height: 80px;
        margin-bottom: 10px;
        font-size: 1.5rem
    }

    .join-modal .form-control::placeholder {
        font-size: 1.5rem
    }

    .join-modal .form-label {
        font-size: 1.5rem
    }

    .join-modal .modal-join-btn {
        height: 80px;
        font-size: 1.5rem;
        border-radius: 80px;
    }

    .join-modal .p-text-3 {
        font-size: 1.375rem;
    }

    .join-modal .modal-col-2 {
        padding-left: 40px;
    }




}

@media (min-width: 1281px) and (max-width: 1440px) {
    .h-banner .h-text-1 {
        font-size: 3.75rem;
    }

    .h-banner .p-text-1 {
        font-size: 1.25rem;
    }

    .h-banner .btn-banner-join {
        font-size: 1.1rem;
    }

    .h-section-3 .div-revolutionary p {
        font-size: 0.75rem;
    }

    .h-section-3 .h-text-1 {
        font-size: 3.4rem;
    }

    .h-section-3 .p-text-1 {
        font-size: 1.25rem;
    }

    .h-section-3 .h-text-2 {
        font-size: 1.375rem;
    }

    .h-section-3 .p-text-2 {
        font-size: 1rem;
    }

    .h-section-4 .h-text-1 {
        font-size: 1.8rem;
    }

    .h-section-4 .p-text-1 {
        font-size: 1rem;
    }

    .h-section-4 .h-text-2 {
        font-size: 2.75rem;
    }

    .h-section-4 .p-text-2 {
        font-size: 1.125rem;
    }

    .h-section-5 .job-card-div p {
        font-size: 1.1rem;
    }

    .h-section-5 .card-hsec-5-m {
        margin-top: 20px;
    }

    .h-section-5 .h-text-1 {
        font-size: 2.125rem;
    }

    .h-section-5 .p-text-1 {
        font-size: 1rem;
    }

    .h-section-5 .h-text-2 {
        font-size: 1rem;
    }

    .h-section-5 .btn-banner-join {
        font-size: 1.1rem;
    }

    .h-section-6 .div-management p {
        font-size: 0.75rem;

    }

    .h-section-6 .h-text-1 {
        font-size: 3rem;
    }

    .h-section-6 .p-text-1 {
        font-size: 1.25rem;
    }

    .h-section-6 .h-text-2 {
        font-size: 1.75rem;
    }

    .h-section-6 .p-text-3 {
        font-size: 1rem;
    }

    .h-section-6 .p-text-4 {
        font-size: 1.125rem;
    }

    .h-section-6 .h-text-3 {
        font-size: 2rem;
    }

    .h-section-6 .p-text-2 {
        font-size: 1.25rem;
    }

    .h-section-7 .access-div p {
        font-size: 1.1rem;
    }

    .h-section-7 .h-text-1 {
        font-size: 3rem;
    }

    .h-section-7 .p-text-1 {
        font-size: 1.25rem;
    }

    .h-section-7 .p-text-2 {
        font-size: 7.25rem;
    }

    .footer .p-text-1 {
        font-size: 1.25rem;
    }

    .footer .foot-link {
        font-size: 1.25rem;
    }

    .footer .h-text-1 {
        font-size: 1.5rem;
    }

    .footer .h-text-2 {
        font-size: 1.375rem;
    }

    .footer .btn-join-foot {
        font-size: 1.125rem;
    }

    .about .h-text-1 {
        font-size: 3.75rem;
    }

    .about .p-text-1 {
        font-size: 1.25rem;
    }

    .about .h-text-2 {
        font-size: 3.4rem;
    }

    .about .p-text-2 {
        font-size: 1.25rem;
    }

    .about .h-text-3 {
        font-size: 2rem;
    }

    .about .p-text-3 {
        font-size: 1.25rem;
    }


    .about .story-div p {
        font-size: 0.75rem;
    }

    .contact .h-text-1 {
        font-size: 3.75rem;
    }

    .contact .p-text-1 {
        font-size: 1.25rem;
    }

    .contact .h-text-2 {
        font-size: 1.625rem;
    }

    .contact .p-text-2 {
        font-size: 1.125rem;
    }

    .contact .div-contact p {
        font-size: 0.75rem;
    }

    .join-modal .r-h-text-1 {
        font-size: 3.625rem;
    }

    .join-modal .r-p-text-1 {
        font-size: 1.375rem;
    }

    .join-modal .r-p-text-2 {
        font-size: 1.25rem;
    }

    .join-modal .r-p-text-3 {
        font-size: 1.25rem;
    }





    .join-modal .h-text-1 {
        font-size: 4.5rem;
    }

    .join-modal .p-text-1 {
        font-size: 1.625rem;
    }

    .join-modal .p-text-2 {
        font-size: 1rem;
    }

    .join-modal .form-control {
        height: 60px;
        margin-bottom: 10px;
        font-size: 1.25rem
    }

    .join-modal .form-select {
        height: 60px;
        margin-bottom: 10px;
        font-size: 1.25rem
    }


    .join-modal .dropdown-header {
        height: 60px;
        margin-bottom: 10px;
        font-size: 1.25rem
    }

    .join-modal .form-control::placeholder {
        font-size: 1.25rem
    }

    .join-modal .form-label {
        font-size: 1.25rem
    }

    .join-modal .modal-join-btn {
        height: 60px;
        font-size: 1.5rem;
        border-radius: 80px;
    }

    .join-modal .p-text-3 {
        font-size: 1.25rem;
    }

    .join-modal .modal-col-2 {
        padding-left: 40px;
    }


}

@media (min-width: 721px) and (max-width: 1280px) {
    .h-banner .h-text-1 {
        font-size: 3.5rem;

    }

    .h-banner .p-text-1 {
        font-size: 1.25rem;
    }

    .h-banner .btn-banner-join {
        font-size: 1rem;
    }

    .h-section-3 .div-revolutionary p {
        font-size: 0.75rem;
    }

    .h-section-3 .h-text-1 {
        font-size: 2.25rem;
    }

    .h-section-3 .p-text-1 {
        font-size: 1rem;
    }

    .h-section-3 .h-text-2 {
        font-size: 0.875rem;
    }

    .h-section-3 .p-text-2 {
        font-size: 0.875rem;
    }

    .h-section-4 .h-text-1 {
        font-size: 1.5rem;
    }

    .h-section-4 .p-text-1 {
        font-size: 0.875rem;
    }

    .h-section-4 .h-text-2 {
        font-size: 3rem;
    }

    .h-section-4 .p-text-2 {
        font-size: 0.875rem;
    }

    .h-section-5 .job-card-div p {
        font-size: 0.75rem;
        letter-spacing: 1px;
        line-height: 140%;
    }

    .h-section-5 .card-hsec-5-m {
        margin-top: 12px;
    }

    .h-section-5 .h-text-1 {
        font-size: 1.75rem;
    }

    .h-section-5 .card-img-hs5 {
        width: 28px;
        height: 28px;
    }

    .h-section-5 .p-text-1 {
        font-size: 0.875rem;
    }

    .h-section-5 .h-text-2 {
        font-size: 0.875rem;
    }

    .h-section-5 .btn-banner-join {
        font-size: 1rem;
    }

    .h-section-6 .div-management p {
        font-size: 0.75rem;

    }

    .h-section-6 .h-text-1 {
        font-size: 1.75rem;
    }

    .h-section-6 .p-text-1 {
        font-size: 0.875rem;

    }

    .h-section-6 .h-text-2 {
        font-size: 1.25rem;
    }

    .h-section-6 .p-text-3 {
        font-size: 0.75rem;
    }

    .h-section-6 .p-text-4 {
        font-size: 0.875rem;
    }

    .h-section-6 .h-text-3 {
        font-size: 1.875rem;
    }

    .h-section-6 .p-text-2 {
        font-size: 1rem;
    }

    .h-section-7 .access-div p {
        font-size: 0.75rem;
    }

    .h-section-7 .h-text-1 {
        font-size: 2.375rem;
    }

    .h-section-7 .p-text-1 {
        font-size: 1.125rem;
    }

    .h-section-7 .p-text-1 {
        font-size: 1rem;
    }

    .h-section-7 .p-text-2 {
        font-size: 6.25rem;
    }

    .footer .p-text-1 {
        font-size: 1rem;
    }

    .footer .foot-link {
        font-size: 1rem;
    }

    .footer .h-text-1 {
        font-size: 1rem;
    }

    .footer .h-text-2 {
        font-size: 1rem;
    }

    .footer .btn-join-foot {
        font-size: 1rem;
    }

    .about .h-text-1 {
        font-size: 3.5rem;
    }

    .about .p-text-1 {
        font-size: 1.3rem;
    }

    .about .h-text-2 {
        font-size: 2.5rem;
    }

    .about .p-text-2 {
        font-size: 1rem;
    }

    .about .h-text-3 {
        font-size: 1.75rem;
    }

    .about .p-text-3 {
        font-size: 1rem;
    }


    .about .story-div p {
        font-size: 0.75rem;
    }


    .contact .h-text-1 {
        font-size: 3.5rem;
    }

    .contact .p-text-1 {
        font-size: 1.125rem;
    }

    .contact .h-text-2 {
        font-size: 1.375rem;
    }

    .contact .p-text-2 {
        font-size: 1rem;
    }

    .contact .div-contact p {
        font-size: 0.75rem;
    }

    .join-modal .r-h-text-1 {
        font-size: 3.6rem;
    }

    .join-modal .r-p-text-1 {
        font-size: 1.5rem;
    }

    .join-modal .r-p-text-2 {
        font-size: 1.125rem;
    }

    .join-modal .r-p-text-3 {
        font-size: 1rem;
    }





    .join-modal .h-text-1 {
        font-size: 3.3rem;
    }

    .join-modal .p-text-1 {
        font-size: 1.25rem;
    }

    .join-modal .p-text-2 {
        font-size: 0.875rem;
    }

    .join-modal .form-control {
        height: 45px;
        font-size: 0.875rem;
        margin-bottom: 3px;
    }

    .join-modal .form-select {
        height: 45px;
        font-size: 0.875rem;
        margin-bottom: 3px;
    }

    .join-modal .dropdown-header {
        height: 45px;
        font-size: 0.875rem;
        margin-bottom: 3px;
    }


    .join-modal .form-control::placeholder {
        font-size: 0.875rem
    }

    .join-modal .form-label {
        font-size: 0.875rem
    }

    .join-modal .modal-join-btn {
        height: 40px;
        font-size: 1rem;
        border-radius: 80px;
    }

    .join-modal .p-text-3 {
        font-size: 0.875rem;
    }

    .join-modal .modal-col-2 {
        padding-left: 20px;
    }



}

@media (min-width: 320px) and (max-width: 720px) {
    .h-banner {
        padding-left: 20px;
    }

    .h-banner .h-text-1 {
        margin-top: 20px;
        font-size: 3rem;
    }

    .h-banner .p-text-1 {
        font-size: 1.2rem;
    }

    .h-banner .btn-banner-join {
        font-size: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .h-section-3 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .h-section-3 .div-revolutionary p {
        font-size: 0.75rem;
    }

    .h-section-3 .h-text-1 {
        font-size: 1.75rem;
    }

    .h-section-3 .p-text-1 {
        font-size: 0.875rem;
    }

    .h-section-3 .h-text-2 {
        font-size: 1.125rem;
    }

    .h-section-3 .p-text-2 {
        font-size: 0.875rem;
    }

    .h-section-3 .card-img-hs3 {
        width: 28px;
        height: 28px;
    }

    .h-section-4 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .h-section-4 .h-text-1 {
        font-size: 1.1rem;
    }

    .h-section-4 .p-text-1 {
        font-size: 1rem;
    }

    .h-section-4 .h-text-2 {
        font-size: 3rem;
    }

    .h-section-4 .p-text-2 {
        font-size: 1.125rem;
    }

    .h-section-5 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .h-section-5 .job-card-div p {
        font-size: 0.75rem;
    }

    .h-section-5 .h-text-1 {
        font-size: 1.875rem;
    }

    .h-section-5 .p-text-1 {
        font-size: 0.875rem;
    }

    .h-section-5 .card-img-hs5 {
        width: 28px;
        height: 28px;
    }

    .h-section-5 .h-text-2 {
        font-size: 0.938rem;
    }

    .h-section-5 .btn-banner-join {
        margin-top: 10px;
        font-size: 1rem;
        width: 100%;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .h-section-6 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .h-section-6 .div-management p {
        font-size: 0.75rem;
    }

    .h-section-6 .h-text-1 {
        font-size: 1.5rem;
    }

    .h-section-6 .p-text-1 {
        font-size: 0.875rem;
    }

    .h-section-6 .h-text-2 {
        font-size: 1.125rem;
    }

    .h-section-6 .p-text-3 {
        font-size: 0.75rem;
    }

    .h-section-6 .p-text-4 {
        font-size: 0.875rem;
    }

    .h-section-6 .h-text-3 {
        font-size: 1.875rem;
    }

    .h-section-6 .p-text-2 {
        font-size: 1rem;
    }

    .h-section-7 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .h-section-7 .access-div p {
        font-size: 0.75rem;
    }

    .h-section-7 .h-text-1 {
        font-size: 2rem;
    }

    .h-section-7 .p-text-1 {
        font-size: 1.125rem;
    }

    .h-section-7 .p-text-1 {
        font-size: 1rem;
    }

    .h-section-7 .p-text-2 {
        font-size: 6.25rem;
    }

    .about .h-text-1 {
        font-size: 3rem;
    }

    .about .p-text-1 {
        font-size: 1rem;
    }

    .about .h-text-2 {
        font-size: 1.75rem;
    }

    .about .p-text-2 {
        font-size: 0.875rem;
    }

    .about .h-text-3 {
        font-size: 1.5rem;
    }

    .about .p-text-3 {
        font-size: 0.875rem;
    }


    .about .story-div p {
        font-size: 0.75rem;
    }

    .contact .h-text-1 {
        font-size: 3rem;
    }

    .about .p-text-1 {
        font-size: 1rem;
    }

    .contact .h-text-2 {
        font-size: 1.125rem;
    }

    .contact .p-text-2 {
        font-size: 0.875rem;
    }

    .contact .div-contact p {
        font-size: 0.75rem;
    }

    .join-modal .r-h-text-1 {
        font-size: 1.875rem;
    }

    .join-modal .r-p-text-1 {
        font-size: 1.125rem;
    }

    .join-modal .r-p-text-2 {
        font-size: 0.875rem;
    }

    .join-modal .r-p-text-3 {
        font-size: 0.875rem;
    }




    .join-modal .h-text-1 {
        font-size: 3rem;
    }

    .join-modal .p-text-1 {
        font-size: 1.125rem;
    }

    .join-modal .p-text-2 {
        font-size: 0.75rem;
    }

    .join-modal .form-control {
        height: 45px;
        font-size: 0.875rem;
        margin-bottom: 3px;
    }

    .join-modal .form-select {
        height: 45px;
        font-size: 0.875rem;
        margin-bottom: 3px;
    }

    .join-modal .dropdown-header {
        height: 45px;
        font-size: 0.875rem;
        margin-bottom: 3px;
    }

    .join-modal .form-control::placeholder {
        font-size: 0.875rem
    }

    .join-modal .form-label {
        font-size: 0.875rem
    }


    .join-modal .modal-join-btn {
        height: 45px;
        font-size: 1rem;
        border-radius: 80px;
    }

    .join-modal .p-text-3 {
        font-size: 0.875rem;
    }

    .join-modal .modal-col-2 {
        padding-left: 20px;
    }

}



























@media all and (min-width: 992px) {
    .desktop-hide {
        display: none !important;
    }
}


@media (min-width: 768px) and (max-width: 991px) {
    .tab-hide {
        display: none !important;
    }
}


@media(max-width:991px) {
    .top-navbar .navbar-linear-bg {
        width: 90%;

    }

    .h-section-7 .p-text-2 {
        font-size: 70px;
    }

    .join-modal .modal-col-2 {
        padding: 0;
        border: none;
    }

    .join-modal .modal-col-1 {
        padding: 0;
    }

    .join-modal .btn-close-btn {
        padding: 5px;
    }

    .join-modal .btn-close-btn img {
        width: 30px;
    }

    .top-navbar .navbar-mobile {
        height: 80vh !important;
    }

    .top-navbar .navbar-mobile.navbar-linear-bg {
        border-radius: 20px !important;
    }

    .top-navbar .navbar-mobile.navbar {
        padding-top: 20px;
        align-items: start !important;
        border-radius: 20px !important;
    }


}

@media(max-width:767px) {
    .mobile-hide {
        display: none !important;
    }

    .top-navbar {
        width: 100%;
    }

    .top-navbar .navbar-linear-bg {
        width: 90%;
    }

    .top-navbar .navbar {
        width: 100%;
    }

    .top-navbar .btn-join {
        padding: 0px 10px;
    }




    .h-section-6 .card-right-car {
        padding: 20px 15px;
    }

    .h-section-6 .profit-img-div {
        display: flex;
        justify-content: end;
    }

    .h-section-6 .profitable-img {
        width: 60%;
        height: auto;
    }


    .footer .copy-right {
        padding: 10px;
    }

    .footer .p-copy {
        font-size: 14px;
    }

    .join-modal .modal-col-2 {
        padding: 0;
        border: none;
    }

    .join-modal .modal-col-1 {
        padding: 0;
    }

    .join-modal .modal-navbar {
        width: 90%;
    }

    .h-section-4 .b-right-4 {
        border: none;
    }

    .about .h-text-1 {
        font-size: 28px;
        text-align: center;
    }

    .about .h-text-2 {
        font-size: 24px;
        text-align: center;
    }

    .about .card-1 {
        border: none;
    }

    .contact .h-text-1 {
        font-size: 28px;
    }

    .contact .card {
        padding: 10px;
    }

    .privacy-policy .privacy-body {
        width: 90%;
    }
    .privacy-policy .h-text-1{
        font-size: 30px;
    }

}

@media(max-width:739px) {

    .xs-hide {
        display: none !important;
    }

}
@media(max-width:600px) {
    .break {
        display: none;
    }

    .h-section-5 {
        width: 90%;
    }

    .h-section-6 {
        padding: 0;
    }

}

