@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: "Urbanist", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(107.56deg, #EEF2F3 0%, #DADEE2 100%); */
  scroll-behavior: smooth;
  background-color:#F2F6F7;

}

code {
  font-family: "Urbanist", sans-serif !important;
}
